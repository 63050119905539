var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "gridTableLayout",
      class: _vm.classes,
      on: { focusin: _vm.onFocusInside, focusout: _vm.onFocusOut }
    },
    [
      _c(
        "div",
        { staticClass: "grid-table-layout__body" },
        [
          _vm.isPrefetchingData || !_vm.isLayoutResolved
            ? _c("Preloader")
            : [
                _vm.isSelectColumn
                  ? _c(
                      "GridTableLayoutPinnedSection",
                      {
                        attrs: {
                          "pinned-state": _vm.pinnedState.LEFT,
                          sections: _vm.pinnedSections
                        }
                      },
                      [
                        _vm.multiselect
                          ? _c("GridMultiSelectRowColumn", {
                              style: _vm.templateRows,
                              attrs: {
                                "column-index": 0,
                                "data-count": _vm.dataCount,
                                "disabled-rows": _vm.disabledRows,
                                "rows-offset": _vm.rowsOffset,
                                "row-ids": _vm.rowIds,
                                "selected-rows": _vm.selectedRows,
                                "excluded-from-selection-rows":
                                  _vm.excludedFromSelectionRows,
                                "is-basic-filter": _vm.isBasicFilter,
                                "is-selected-all": _vm.isSelectedAll
                              },
                              on: {
                                "rows-select": _vm.onRowsSelect,
                                "excluded-rows-select":
                                  _vm.onExcludedRowsSelect,
                                "select-all": _vm.onSelectAllRows
                              }
                            })
                          : _c("GridSelectRowColumn", {
                              style: _vm.templateRows,
                              attrs: {
                                "column-index": 0,
                                "data-count": _vm.dataCount,
                                "disabled-rows": _vm.disabledRows,
                                "rows-offset": _vm.rowsOffset,
                                "row-ids": _vm.rowIds,
                                "selected-rows": _vm.selectedRows,
                                "is-basic-filter": _vm.isBasicFilter
                              },
                              on: { "rows-select": _vm.onRowsSelect }
                            })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "GridTableLayoutColumnsSection",
                  { ref: "columnsSection", style: _vm.templateColumns },
                  [
                    _vm.isSelectColumn
                      ? _c("GridSentinelColumn", {
                          attrs: { "pinned-state": _vm.pinnedState.LEFT },
                          on: { sticky: _vm.onStickyChange }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(_vm.orderedColumns, function(column, columnIndex) {
                      return [
                        _vm.columnComponents[column.type]
                          ? _c(_vm.columnComponents[column.type], {
                              key: column.id,
                              tag: "Component",
                              style: _vm.templateRows,
                              attrs: {
                                scope: _vm.scope,
                                "column-index": columnIndex + _vm.columnsOffset,
                                column: column,
                                rows: _vm.rows,
                                "rows-offset": _vm.rowsOffset,
                                "sort-order": _vm.sortOrder,
                                filters: _vm.filters,
                                "data-filter-cell-components":
                                  _vm.dataFilterCellComponents,
                                "data-cell-components": _vm.dataCellComponents,
                                "row-ids": _vm.rowIds,
                                errors: _vm.errors,
                                "disabled-rows": _vm.disabledRows,
                                drafts: _vm.drafts,
                                "selected-rows": _vm.selectedRows,
                                "excluded-from-selection-rows":
                                  _vm.excludedFromSelectionRows,
                                "is-basic-filter": _vm.isBasicFilter,
                                "is-editable": _vm.isEditable,
                                "is-selected-all": _vm.isSelectedAll
                              },
                              on: {
                                remove: _vm.onRemoveColumn,
                                swap: _vm.onSwapColumns,
                                resize: _vm.onResizeColumn,
                                sort: _vm.onSortColumn,
                                "edit-filter-cell": _vm.onEditFilterCell,
                                "filter-value": _vm.onFilterValueChange,
                                "filter-clear": _vm.onFilterValueClear,
                                "cell-value": _vm.onCellValueChange,
                                "edit-cell": _vm.onEditCell
                              }
                            })
                          : _c("GridDraggableDataColumn", {
                              key: column.id,
                              style: _vm.templateRows,
                              attrs: {
                                scope: _vm.scope,
                                "column-index": columnIndex + _vm.columnsOffset,
                                column: column,
                                rows: _vm.rows,
                                "rows-offset": _vm.rowsOffset,
                                "sort-order": _vm.sortOrder,
                                filters: _vm.filters,
                                "data-filter-cell-components":
                                  _vm.dataFilterCellComponents,
                                "data-cell-components": _vm.dataCellComponents,
                                "row-ids": _vm.rowIds,
                                errors: _vm.errors,
                                "disabled-rows": _vm.disabledRows,
                                drafts: _vm.drafts,
                                "selected-rows": _vm.selectedRows,
                                "excluded-from-selection-rows":
                                  _vm.excludedFromSelectionRows,
                                "is-basic-filter": _vm.isBasicFilter,
                                "is-editable": _vm.isEditable,
                                "is-selected-all": _vm.isSelectedAll,
                                values: _vm.values
                              },
                              on: {
                                remove: _vm.onRemoveColumn,
                                swap: _vm.onSwapColumns,
                                resize: _vm.onResizeColumn,
                                sort: _vm.onSortColumn,
                                "edit-filter-cell": _vm.onEditFilterCell,
                                "filter-value": _vm.onFilterValueChange,
                                "filter-clear": _vm.onFilterValueClear,
                                "cell-value": _vm.onCellValueChange,
                                "edit-cell": _vm.onEditCell
                              }
                            })
                      ]
                    }),
                    _vm._v(" "),
                    _vm.actionColumns.length
                      ? _c("GridSentinelColumn", {
                          style: { gridColumn: "" + _vm.orderedColumns.length },
                          attrs: { "pinned-state": _vm.pinnedState.RIGHT },
                          on: { sticky: _vm.onStickyChange }
                        })
                      : _vm._e()
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "GridTableLayoutPinnedSection",
                  {
                    attrs: {
                      "pinned-state": _vm.pinnedState.RIGHT,
                      sections: _vm.pinnedSections
                    }
                  },
                  _vm._l(_vm.actionColumns, function(column, columnIndex) {
                    return _c("GridRowActionColumn", {
                      key: column.id,
                      style: _vm.templateRows,
                      attrs: {
                        column: column,
                        "action-cell-components": _vm.actionCellComponents,
                        columns: _vm.actionColumns,
                        rows: _vm.rows,
                        "disabled-rows": _vm.disabledRows,
                        "row-ids": _vm.rowIds,
                        "rows-offset": _vm.rowsOffset,
                        "columns-offset":
                          _vm.orderedColumns.length +
                          columnIndex +
                          _vm.columnsOffset,
                        "selected-rows": _vm.selectedRows,
                        "excluded-from-selection-rows":
                          _vm.excludedFromSelectionRows,
                        "is-basic-filter": _vm.isBasicFilter,
                        "is-selected-all": _vm.isSelectedAll
                      },
                      on: { "row-action": _vm.onRowAction }
                    })
                  }),
                  1
                )
              ],
          _vm._v(" "),
          _vm.editCell
            ? _c(
                _vm.editCellComponent,
                _vm._b(
                  {
                    ref: "editCell",
                    tag: "Component",
                    on: {
                      "cell-value": _vm.onCellValueChange,
                      dismiss: _vm.onDismissEditCell
                    }
                  },
                  "Component",
                  _vm.editCell.props,
                  false
                )
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.editFilterCell
            ? _c(
                _vm.editFilterCellComponent,
                _vm._b(
                  {
                    ref: "editCell",
                    tag: "Component",
                    on: {
                      "filter-value": _vm.onFilterValueChange,
                      "filter-clear": _vm.onFilterValueClear,
                      dismiss: _vm.onDismissEditFilterCell
                    }
                  },
                  "Component",
                  _vm.editFilterCell.props,
                  false
                )
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.cellResizer
            ? _c(
                "GridCellResizer",
                _vm._b(
                  {
                    on: {
                      resize: _vm.onCellResize,
                      copy: _vm.onCopyCellValuesByResizing
                    }
                  },
                  "GridCellResizer",
                  _vm.cellResizer,
                  false
                )
              )
            : _vm._e()
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }